import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { IoSearch } from "react-icons/io5"
import styled from "styled-components"
import BookResults from "../components/BookResults"
import Button from "../components/Button"
import CriteriaList from "../components/CriteriaList"
import Layout from "../components/Layout"
import Themes from "../components/Themes"
import { fetchApi, filterStartingWith } from "../components/utils"

const CriteriaLinks = styled.div`
  text-align: center;

  > * + * {
    margin-left: 1em;
  }

  @media (max-width: 800px) {
    > * {
      display: block;
      + * {
        margin-left: 0;
        margin-top: 0.5em;
      }
    }
  }
`

const alefbet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")

export default function SearchPage({ location }) {
  const [themes, setThemes] = useState([])
  const [authors, setAuthors] = useState([])
  const [publishers, setPublishers] = useState([])

  const [searchResults, setSearchResults] = useState([])
  const [searchTitle, setSearchTitle] = useState("")
  const [searchTitleExtra, setSearchTitleExtra] = useState("")

  const search = new URLSearchParams(location.search)
  const searchTheme = search.get("theme")
  const searchAuthor = search.get("auteur")
  const searchPublisher = search.get("editeur")
  const searchTerm = search.get("terme")

  useEffect(() => {
    // Fetch criteria
    fetchApi("/themes", setThemes)
    fetchApi("/recensions/book-authors", setAuthors)
    fetchApi("/recensions/book-publishers", setPublishers)

    // Fetch results
    if (searchTheme) {
      fetchApi(`/themes/${searchTheme}?withResources=true`, (data) => {
        setSearchTitle(data.name)
        if (data.parent) setSearchTitleExtra(data.parent.name)
        else setSearchTitleExtra("")
        setSearchResults(data.recensions)
      })
    } else if (searchAuthor) {
      fetchApi("/recensions?bookAuthor=" + searchAuthor, setSearchResults)
      setSearchTitle(searchAuthor)
      setSearchTitleExtra("Auteur")
    } else if (searchPublisher) {
      fetchApi("/recensions?bookPublisher=" + searchPublisher, setSearchResults)
      setSearchTitle(searchPublisher)
      setSearchTitleExtra("Éditeur")
    } else if (searchTerm) {
      fetchApi(`/search?term=${encodeURIComponent(searchTerm)}`, (data) =>
        setSearchResults(data.recensions)
      )
      setSearchTitle(searchTerm)
      setSearchTitleExtra("Terme")
    } else {
      setSearchTitleExtra("")
      setSearchTitle("")
    }
  }, [searchTheme, searchAuthor, searchPublisher, searchTerm])

  return (
    <Layout>
      {searchTitle ? (
        <BookResults
          title={searchTitle}
          titleExtra={searchTitleExtra}
          results={searchResults}
          noResult="Il n'y a pas encore de résultat pour cette recherche."
        />
      ) : (
        <section style={{ marginTop: 100, marginBottom: 100 }}>
          <h1 style={{ marginBottom: "0.5em" }}>Rechercher une recension</h1>
          <CriteriaLinks>
            <a href="#themes">
              <Button>Par thème</Button>
            </a>
            <a href="#auteurs">
              <Button>Par auteur</Button>
            </a>
            <a href="#editeurs">
              <Button>Par éditeur</Button>
            </a>
            <span>ou</span>
            <form style={{ display: "inline-block" }}>
              <input
                type="text"
                name="terme"
                placeholder="Par mot-clé"
                style={{ lineHeight: 1.75, vetricalAlign: "bottom" }}
              />
              <Button type="submit">
                <IoSearch style={{ verticalAlign: "-0.2em" }} />
              </Button>
            </form>
          </CriteriaLinks>

          <h2 id="themes">Rechercher par thème</h2>
          <CriteriaList big>
            <Themes themes={themes} />
          </CriteriaList>

          <h2 id="auteurs">Rechercher par auteur</h2>
          <CriteriaList big>
            <ul>
              {alefbet.map((letter) => (
                <li key={letter}>
                  {letter}
                  <ul>
                    {filterStartingWith(authors, letter).map((author) => (
                      <li key={author}>
                        <Link to={"?auteur=" + author}>{author}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </CriteriaList>

          <h2 id="editeurs">Rechercher par éditeur</h2>
          <CriteriaList big>
            <ul>
              {alefbet.map((letter) => (
                <li key={letter}>
                  {letter}
                  <ul>
                    {filterStartingWith(publishers, letter).map((publisher) => (
                      <li key={publisher}>
                        <Link to={"?editeur=" + publisher}>{publisher}</Link>
                      </li>
                    ))}
                  </ul>
                </li>
              ))}
            </ul>
          </CriteriaList>
        </section>
      )}
    </Layout>
  )
}
